@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

#loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center
}
.position-relative-models svg.replaced-svg { height: auto !important; width: auto; top:5px; }

/** CONTACT **/
.error-input {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.error-input:focus{
  box-shadow: 0 0 0 3px rgb(220, 53, 69, 0.4);
  border: 1px solid lightgray;
  border-radius: .25rem;
}
.error-select {
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}
.error-select:focus{
  box-shadow: 0 0 0 3px rgb(220, 53, 69, 0.4);
  border: 1px solid lightgray;
  border-radius: .25rem;
}
.error-checkbox {
  color: #dc3545;
}
.error-checkbox::before {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 3px rgb(220, 53, 69, 0.3) !important;
  border: 1px solid lightgray;
  border-radius: .25rem !important;
}
.success-input {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.success-input:focus{
  box-shadow: 0 0 0 3px rgb(40, 167, 69, 0.4);
  border: 1px solid lightgray;
  border-radius: .25rem;
}
.success-select {
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}
.success-select:focus{
  box-shadow: 0 0 0 3px rgb(40, 167, 69, 0.4);
  border: 1px solid lightgray;
  border-radius: .25rem;
}
.success-checkbox {
  color: #28a745;
}
.success-checkbox::before {
  border-color: #34ce57 !important;
  background-color: #34ce57 !important;
  box-shadow: 0 0 0 3px rgb(52, 206, 87, 0.4) !important;
  border: 1px solid lightgray;
  border-radius: .25rem;
}
.error-msg {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

/*.header-contact {
  background-image: url('../../../../../common/resources/assets/images/bg-contact-2.jpg');
}*/

.padding-modal-reservation{
  padding-right: 5px;
  padding-left: 5px;
}

.btn:hover {
  opacity: 1.00 !important;
}

.btn-cta {
  bottom: 24px !important;
}

.dropdown-menu {
  z-index: 1500 !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.cursor-zoom-in{
  cursor: zoom-in;
}
.cursor-zoom-out{
  cursor: zoom-out;
}
.cursor-crosshair{
  cursor: crosshair;
}