@charset "UTF-8";
*,
*::after,
*::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: none !important;
}
*::-webkit-scrollbar {
	display: block;
	width: 10px;
}
*::-webkit-scrollbar-thumb{
	background: rgb(119, 119, 119);
	border-radius: 20px;
}
* {
	-ms-overflow-style: none !important;
}

/* --- CSS Variables --- */
:root {
	--primary: #002e6b;
	--secondary: #00aad2;
	--link: #00aad2;
	--sand: #e4dcd3;
	--lightsand: #f6f3f2;
	--skyblue: #aacae6;
	--active-red: #e63312;
	--gold: #a36b4f;
	--warning: #f2994a;
	--warning-gold: #a36b4f;
	--dark: #333333;
	--text: #666666;
	--gray: #999999;
	--lines: #dddddd;
	--fog: #eeeeee;
	--light: #f2f4f6;
	--font-family-head: "Hyundai Sans Head";
	--font-family-text: "Hyundai Sans Text";
	--border-radius: 0;
}

/* ---------------- GENERAL --------------- */
html,
body {
	font-size: 16px;
}
html {
	height: 100%;
}
body {
	font-family: var(--font-family-text), sans-serif;
	color: var(--text);
	background-color: var(--white);
	line-height: 1.6;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* --- Links */
a,
a:hover,
a:focus,
a:active,
a.active {
	color: var(--link);
	text-decoration: none;
	outline: none;
}
a:hover {
	color: var(--primary);
}
a[role="button"],
button {
	cursor: pointer;
}

/* --- Typography */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: var(--font-family-head), sans-serif;
	color: var(--primary);
	font-weight: 500;
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;
}
h6 {
	font-size: 1em;
}
.display-1 {
	font-family: var(--font-family-head);
}
ion-icon {
	line-height: 1 !important;
	margin: 0;
	padding: 0;
	position: relative;
	top: 2px;
	--ionicon-stroke-width: 40px !important;
}
div p,
div ul {
	margin-bottom: 1em;
}
div p:last-child,
div ul:last-child {
	margin-bottom: 0 !important;
}
.lead {
	letter-spacing: -0.04rem;
}
.fw-medium {
	font-weight: 500;
}
.unstretched-link {
	position: relative;
	z-index: 100;
}
.lh-condensed {
	line-height: 1.2 !important;
	display: block;
}
.lh-1 {
	line-height: 1em;
}
.list-group.sidebar li {
	padding-left: 0.6em;
	padding-right: 0.6em;
}
.nav-sm {
	font-size: 0.9em;
}
dl dt {
	font-family: var(--font-family-head);
	font-weight: 500;
}
dl .w-25 {
	width: 40px !important;
	height: auto;
}
.top-0 {
	top: 0;
}
.right-0 {
	right: 0;
}
.left-0 {
	left: 0;
}
.small-lg {
	font-size: 0.8rem !important;
}
.small-md {
	font-size: 0.7rem !important;
}

/* --- Colors */
.text-primary {
	color: var(--primary) !important;
}
.text-secondary {
	color: var(--secondary) !important;
}
.text-sand {
	color: var(--sand) !important;
}
.text-gold {
	color: var(--gold) !important;
}

.bg-primary {
	background-color: var(--primary) !important;
}
.bg-secondary {
	background-color: var(--secondary) !important;
}
.bg-light {
	background-color: var(--light) !important;
}
.bg-sand {
	background-color: var(--sand) !important;
}

.border-secondary {
	border-color: var(--secondary) !important;
}

/* --------------- NAVBAR ----------------- */
#topnav {
	font-size: 0.9em !important;
}
#social-list a {
	padding: 4px;
}
#social-list ion-icon {
	top: 4px;
	position: relative;
}

.navbar {
	z-index: 1040;
	font-family: var(--font-family-head), sans-serif;
}
.navbar form {
	font-family: var(--font-family-text), sans-serif;
}
.navbar-brand {
	font-size: 1.8em; /* position: absolute; left: 50%; top: 2px; transform: translateX(-50%); */
}
.navbar-light .navbar-nav .nav-link {
	color: var(--dark);
	padding: 0 !important;
	margin-left: 2rem;
}
.navbar-light .navbar-nav .nav-link:hover {
	color: var(--dark);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
	color: var(--secondary);
}
.navbar ion-icon {
	font-size: 1.4em;
	top: 5px;
}

/* ----------------- HEADER ---------------- */
#header {
	padding-top: 8vh;
	padding-bottom: 8vh;
	display: flex;
	align-items: center;
	text-align: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
#header.bg-primary::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: var(--dark);
	opacity: 0.5;
}
#header.bg-primary .container {
	position: relative;
	z-index: 3;
}

.breadcrumb-item + .breadcrumb-item::before {
	color: rgba(255, 255, 255, 0.5);
	content: "›";
}
.breadcrumb-item.active {
	color: rgba(255, 255, 255, 0.5);
}

/* --------------- MAIN --------------- */
#main {
	background-color: var(--light);
}
#main section {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
#sidebar-filters {
	z-index: 800;
	font-size: 0.95em;
}

/* Heading Section */
.heading-section {
	margin-bottom: 3rem;
	text-align: center;
}
.heading-section div {
	margin-top: 0.4rem;
}

/* Cards */
.card {
	border-radius: var(--border-radius);
	overflow: hidden;
}
.card svg rect {
	fill: var(--dark);
}
.card svg text {
	display: none;
}
.card-header {
	font-family: var(--font-family-head);
	font-weight: 500;
	color: var(--dark);
	line-height: 1.2;
	display: flex;
	align-items: center;
	padding-top: 1em;
	padding-bottom: 0.8em;
}
.bd-placeholder-img {
	border-radius: var(--border-radius);
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Card Model */
.card-model {
	background-color: transparent;
	border: none;
	overflow: visible;
}
.card-model .card-header {
	background: transparent;
	border: none;
}
.card-model.no-stock .card-img-top {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.7;
}
.card-model .card-body-hidden {
	padding-top: 0;
}
.card-tag {
	left: 0;
	top: 1em;
}
.avatar-xl {
	width: 120px;
	height: auto;
}

/* Card Product */
.card-product .card-body {
	padding-top: 1.8em;
	padding-bottom: 1.8em;
}
.card-product .owl-dots {
	position: absolute;
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
}
.card-product .owl-theme .owl-dots .owl-dot.active span,
.card-product .owl-theme .owl-dots .owl-dot:hover span {
	background: var(--secondary);
}
.card-product .position-absolute {
	font-size: 24px;
	top: 1em;
	left: 1em;
}
.card-product .list-inline-item {
	font-size: 1em;
}
.card-product .list-inline-item + .list-inline-item::before {
	color: rgba(0, 0, 0, 0.2);
	content: "|";
	padding-right: 1em;
}

/* Tabs */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background-color: var(--light);
	border-bottom-color: var(--light);
}

/* CTA Footer */
#cta-footer {
	background-image: url('../../../../../common/resources/assets/images/img_footer.png');
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	padding-bottom: 20vw !important;
	padding-top: 0 !important;
}

/* --- LISTA DE PRODUCTOS */
.product-actions {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 99;
	font-size: 1.5em;
}
.product-actions a {
	display: block;
	color: var(--primary);
	width: 48px;
	height: 48px;
	text-align: center;
	border-radius: 40px;
	background: var(--sand);
}
.product-actions a:hover {
	color: var(--gold);
	background: var(--white);
}

/* Filters */
#filters-accordion .item-accordion {
}
#filters-accordion .btn-header-accordion {
}
#filters-accordion .btn-header-accordion a {
	color: var(--dark);
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--bg);
	padding: 4px 0;
	font-family: var(--font-family-head);
}
#filters-accordion .btn-header-accordion a ion-icon {
	color: var(--gray);
}
#filters-accordion .btn-header-accordion a.collapsed ion-icon {
	transform: rotate(180deg);
}

/* --- PRODUCTO */
#product-info-slider.owl-theme .owl-dots {
	position: absolute;
	width: 100%;
	bottom: -2em;
}
#product-info-slider.owl-theme .owl-dots .owl-dot.active span,
#product-info-slider.owl-theme .owl-dots .owl-dot:hover span {
	background: var(--secondary);
}
.accordion#accordionEquipment {
	padding: 1em 2em;
	background: #fff;
	box-shadow: 0px 16px 40px -16px rgba(0, 0, 0, 0.16);
}
.accordion-item {
	margin: 0 0 0.5em 0;
	padding: 1em 0;
	border-bottom: 2px solid var(--sand);
}
.accordion#accordionEquipment .accordion-item:last-child {
	border-bottom: none;
	margin: 0;
}
.accordion-header a {
	color: var(--primary);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.accordion-header a.collapsed {
	color: var(--dark);
}
.accordion-header a ion-icon {
	color: var(--sand);
	transition: transform 0.3s ease-in-out;
}
.accordion-header a:not(.collapsed) ion-icon {
	transform: rotate(-180deg);
	color: var(--primary);
}
.accordion-body {
	padding-top: 1em;
}
.accordion-body p {
	margin: 0.4em 0;
}
.accordion-body ul {
	list-style: none;
	padding-left: 1.5em;
}
.accordion-body ul li {
	position: relative;
	margin: 0.4em 0;
}
.accordion-body ul li::after {
	content: "";
	position: absolute;
	left: -1.5em;
	top: 0.65em;
	width: 16px;
	height: 1px;
	background: var(--text);
}

/* --- CONTACTO */
#message-confirmation {
	min-height: 50vh;
	display: flex;
	align-items: center;
}
#message-confirmation img {
	width: 96px;
	height: 96px;
}

/* --- COMPARADOR */
#pagina-comparativa .table tbody tr.bg-white {
}
#pagina-comparativa .table tbody th.h6,
#pagina-comparativa .table tbody td.h6 {
	vertical-align: middle;
}

/* --- PAGE ERROR */
#main section#page-error {
	padding-top: 10rem;
	padding-bottom: 10rem;
}

/* --- PAGE UNDER CONSTRUCTION */
#uc section {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("../../../../../common/resources/assets/images/img_page_maintenance_2.jpg") no-repeat center;
	background-attachment: fixed;
	background-size: cover;
}
#uc section::after {
	content: "";
	background: rgba(0, 18, 42, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
}
#uc section .container-fluid {
	position: relative;
	z-index: 99;
}
#uc h1 {
	font-size: 6vw;
}
#uc h2 {
	font-size: 4vw;
}
#uc img {
	width: 20vw;
	max-width: 120px;
	height: auto;
}
hr.mini {
	height: 2px;
	width: 24%;
	margin: 4em auto !important;
	background: var(--white);
}

/* --------------- FOOTER --------------- */
#footer #copyright {
	font-size: 0.9em;
}
.bg-dark > .text-muted {
	color: rgba(255, 255, 255, 0.6) !important;
}
#footer > .container {
	padding-right: 15px;
	padding-left: 15px;
}
#footer .input-group .btn {
	border-radius: 0 var(--border-radius) var(--border-radius) 0;
	padding-left: 2em;
	padding-right: 2em;
}

/* --------------- FORMS & BUTTONS --------------- */
form label {
	font-family: var(--font-family-head);
	font-weight: 500;
	font-size: 0.94em;
	margin-bottom: 0.3em;
	color: var(--dark);
}

.form-control,
.custom-select {
	height: 2.6em;
	border-radius: var(--border-radius);
	border: 1px solid var(--lines);
}

/* --- Selectpicker */
.bootstrap-select button {
	height: 2.6em !important;
	border-radius: var(--border-radius) !important;
	border: 1px solid var(--lines) !important;
	padding: 0.52rem 1rem !important;
	font-family: var(--font-family-text);
	font-weight: 400;
}
.bootstrap-select button:hover,
.bootstrap-select button.focus,
.bootstrap-select button:focus,
.bootstrap-select button:active,
.bootstrap-select button.active {
	background: white !important;
	box-shadow: none !important;
	opacity: 1;
}
.bootstrap-select .dropdown-menu {
	font-size: 1em;
}
.dropdown-item.active,
.dropdown-item:active {
	background-color: var(--secondary) !important;
}
.input-group-lg > .form-control {
	font-size: 1em;
	padding: 1.5em 1em;
}

/* --- Custom checkboxes */
.custom-control-label {
	font-family: var(--font-family-text);
	font-weight: 400;
	padding-left: 0.4em;
	font-size: 1em;
	margin: 0.1em 0;
	vertical-align: middle !important;
	line-height: 2;
}
.custom-control-label:before {
	border-radius: var(--border-radius) !important;
	border: none;
	background-color: var(--lines);
	width: 1.2rem;
	height: 1.2rem;
}
.custom-control-label::after {
	width: 1.1rem;
	height: 1.1rem;
	left: -1.46rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: var(--secondary);
	outline: 0 none !important;
	box-shadow: none !important;
}

/* --- Custom Ranges */
.custom-range::-webkit-slider-thumb {
	background: var(--secondary);
}
.custom-range::-moz-range-thumb {
	background: var(--secondary);
}
.custom-range::-ms-thumb {
	background: var(--secondary);
}

/* --- Color Selectors */
.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}
.form-check .form-check-input {
	position: absolute;
	margin-left: 0%;
	z-index: 1;
	width: 1em;
	height: 1em;
	margin-top: 0.5em;
	vertical-align: top;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 2px solid var(--lines);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}
.form-check .form-check-input[type="checkbox"] {
	border-radius: 0;
}
.form-check .form-check-input:active {
	filter: none;
}
.form-check .form-check-input:focus {
	border-color: yellow;
	outline: 0;
	box-shadow: 0 0 0 0 rgba(254, 105, 106, 1);
}
.form-check .form-check-input:checked {
	background-color: transparent;
	border-color: transparent;
	display: none;
}
.form-check .form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23222' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input:checked ~ .form-option-label {
	border-color: var(--secondary);
	color: var(--secondary);
}
.form-check .form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.6;
	cursor: not-allowed;
}

.form-option {
	padding-left: 0;
	width: 21%;
}
.form-option .form-check-input {
	border: 0;
	background: none;
	left: 0;
}
.form-option .form-option-label {
	position: relative;
	min-width: 2rem;
	height: 2rem;
	margin-bottom: 0;
	padding-top: 0.0625rem;
	padding-right: 0.375rem;
	padding-left: 0.375rem;
	transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
	border: 2px solid var(--lines);
	border-radius: 2rem;
	cursor: pointer;
}
.form-option .form-option-label:hover {
	border-color: var(--secondary);
	color: #373f50;
}
.form-option .form-option-color {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1.5rem;
	height: 1.5rem;
	margin-top: -0.75rem;
	margin-left: -0.75rem;
	background-position: top left;
	background-size: 1.5rem 1.5rem;
	background-repeat: no-repeat;
}
.form-check .fs-xs {
	font-size: 0.8em !important;
	font-family: var(--font-family-text);
	line-height: 1.3;
	font-weight: 400;
}

/* --- BTNS */
.btn {
	font-family: var(--font-family-head);
	font-weight: 500;
	padding: 0.6em 1.2em;
	border-radius: var(--border-radius);
}
.btn-sm {
	font-size: 0.95em;
	padding: 0.75em 1.3em;
}
.btn-lg {
	font-size: 1em;
	padding: 0.8em 1.4rem;
}

.btn-primary {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}
.btn:hover {
	opacity: 0.88 !important;
}
.btn-secondary {
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}
.btn-outline-primary {
	color: var(--primary) !important;
	border-color: var(--primary) !important;
}
.btn-outline-primary:hover {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
	color: #fff !important;
}
.btn-warning {
	background-color: var(--warning) !important;
	border-color: var(--warning) !important;
	color: var(--white) !important;
}
.btn-sand {
	background-color: var(--sand);
	border-color: var(--sand);
	color: var(--primary);
}
.btn-sand:hover {
	background-color: var(--primary);
	border-color: var(--primary);
	color: var(--sand);
}
.btn-gold {
	background-color: var(--gold) !important;
	color: var(--white);
}
.btn-gold:hover,
.btn-gold:focus,
.btn-gold:active {
	background-color: var(--gold);
	border-color: var(--gold);
	color: var(--white);
}

.btn-chat {
	position: fixed;
	bottom: 0px;
	right: 16px;
	border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
	z-index: 180;
}
.btn-cta {
	position: fixed;
	bottom: 64px;
	right: 24px;
	height: 56px;
	width: 56px;
	border-radius: 56px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 200;
	font-size: 1.8em;
	padding: 0;
	text-align: center;
}
.btn-cta ion-icon {
	top: 0;
}

/* ------------------- MODALS --------------------- */
.modal {
	z-index: 2600 !important;
}
.modal#modalBanner .close { 
	position: absolute;
	right: 0.5rem;
	top: 1rem;
	padding: 0 !important;
	margin: 0 !important;
	width: 2.6rem;
	height: 2.68rem;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
}
.modal#modalBanner .close ion-icon {
	top: 0;
}
#modalFilter .input-group .btn {
	height: 2.6em !important;
	padding: 0 1em;
}
.modal-header {
	line-height: 1;
}
.modal-title {
	font-family: var(--font-family-head);
	font-weight: 500;
	position: relative;
	top: 4px;
	color: var(--dark);
	font-size: 1.1em;
}

/* ------------- EXTRAS ------------- */
/* Animations */
a,
input,
select,
textarea,
button,
.btn,
.form-control,
.owl-dot span,
*::after,
*::before {
	-moz-transition: all ease-in-out 0.3s;
	-webkit-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

/* Text Selection */
::-moz-selection {
	text-shadow: none;
	background: var(--sand);
	color: var(--dark);
}
::selection {
	text-shadow: none;
	background: var(--sand);
	color: var(--dark);
}
img::selection {
	background: 0 0;
}
img::-moz-selection {
	background: 0 0;
}

/* Form Placeholder */
.form-control::placeholder {
	color: var(--text);
	opacity: 1 !important;
}
.form-control:-ms-input-placeholder {
	color: var(--text);
}
.form-control::-ms-input-placeholder {
	color: var(--text);
}

/* HR Mini */
hr.hr-mini,
.text-center hr.mini,
hr.hr-center {
	margin: 40px auto;
}
hr.hr-mini {
	width: 30px;
	border-width: 3px;
	border-color: var(--primary);
}
hr.hr-secondary {
	border-color: var(--secondary);
}
hr.hr-sand {
	border-color: var(--sand);
}

/* --------------------------------------------------
----------------- RESPONSIVE CSS --------------------
-------------------------------------------------- */
@media (min-width: 1201px) {
	.container {
		max-width: 1170px;
	}
	.card-model {
		transition: all 0.2s ease-in-out;
	}
	.card-model:hover {
		background: #fff;
		box-shadow: 0px 16px 64px -16px rgba(0, 0, 0, 0.24);
		z-index: 100;
		transform: translateY(-3px);
	}
	.card-model .card-body-hidden {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		margin-top: 0;
		border-radius: var(--border-radius);
		background: #fff;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease-in-out;
	}
	.card-model:hover .card-body-hidden {
		opacity: 1;
		visibility: visible;
		z-index: 998;
		height: auto;
		box-shadow: 0px 16px 64px -16px rgba(0, 0, 0, 0.24);
	}
	#sidebar-filters {
		padding-right: 2em;
	}
}

@media (max-width: 1200px) {
	.navbar-light .navbar-nav .nav-link {
		margin-left: 1.4rem;
	}
}

@media (min-width: 1025px) {
	#sidebar-filters {
		padding-right: 1.2em;
	}
}

@media (max-width: 1024px) {
	body {
		font-size: 16px;
	}
	h1 {
		font-size: 2.3em;
	}
	h5 {
		font-size: 1.2em;
	}
	h6 {
		font-size: 1.1em;
	}
	.navbar-light .navbar-nav .nav-link {
		margin-left: 1rem;
	}
}

@media (min-width: 993px) {
	.navbar-right {
		font-size: 0.96em;
		margin-top: -5px;
	}
}

@media (max-width: 992px) {
	#topnav .container {
		padding-right: 0;
		padding-left: 0;
	}
	.navbar ion-icon {
		top: 3px;
		font-size: 1.1em;
	}
	.navbar-toggler {
		border: none;
	}
	.navbar #searchMobile ion-icon,
	.navbar-toggler ion-icon {
		font-size: 1.4em;
		top: 0;
		color: var(--dark) !important;
	}
	.navbar-collapse {
		flex-basis: content !important;
		overflow-x: hidden;
		overflow-y: scroll !important;
		max-height: 80vh !important;
	}
	.navbar-light .navbar-nav .nav-link {
		color: var(--dark);
		padding: 0.5em 0 !important;
		margin-left: 0;
	}
	.product-actions-bottom {
		margin-top: 2rem;
		font-size: 1.4em;
	}
	.product-actions-bottom a {
		display: block;
		color: var(--primary);
		width: 48px;
		height: 48px;
		text-align: center;
		border-radius: 40px;
		background: var(--sand);
	}
	#footer {
		text-align: center;
	}
}

@media (min-width: 769px) {
	.navbar-brand img {
		height: 22px !important;
		width: auto !important;
	}
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
	#header .h1 {
		font-size: min(max(2.4rem, 4vw), 72px);
	}
	#pagina-comparativa table td.td-car {
		width: 25% !important;
		min-width: 25% !important;
	}
}

@media (max-width: 768px) {
	body {
		min-height: calc(
			100% + (env(safe-area-inset-top) + env(safe-area-inset-bottom))
		);
	}

	h1 {
		font-size: 2rem;
	}
	h5 {
		font-size: 1rem;
	}
	h6 {
		font-size: 1em;
	}

	.navbar-brand {
		top: -2px;
		margin-left: 4px;
	}
	.navbar-brand img {
		height: 19px !important;
		width: auto !important;
	}

	#header {
		padding-top: 3rem;
		padding-bottom: 2rem;
	}

	#main section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	.card-model {
		background: #fff;
		box-shadow: 0px 16px 64px -16px rgba(0, 0, 0, 0.24);
	}
	#pagina-comparativa {
		padding-right: 0 !important;
	}
	#cta-footer {
		padding-bottom: 30vh !important;
	}
	#legal_footer {
		padding-bottom: 6rem !important;
	}
}

@media (max-width: 576px) {
	#topnav .container {
		padding-right: 1rem;
		padding-left: 1rem;
	}
	.accordion#accordionEquipment {
		padding: 1em 1.5em;
	}
}

@media (max-width: 414px) {
	.btn-cta {
		bottom: calc(1.5rem + env(safe-area-inset-bottom));
		right: 0.5rem;
	}
	.btn-chat {
		right: 0;
		left: 0;
		width: 100%;
		padding-bottom: calc(0.6em + env(safe-area-inset-bottom));
	}
	#pagina-comparativa table {
		width: 100% !important;
	}
	#pagina-comparativa table td.td-car {
		width: 300px !important;
		min-width: 300px !important;
	}
}

@media (max-width: 320px) {
	.navbar-brand svg {
		height: 20px !important;
		width: auto !important;
	}
}

@media (max-height: 1080px) {
	#sidebar-filters {
		position: relative !important;
		top: 0 !important;
	}
}
