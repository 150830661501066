/* --- Webfonts --- */
@font-face {
  font-family: "Hyundai Sans Head";
  src: url(../fonts/hyundai/HyundaiSansHead-Medium.woff2) format("woff2"), url(../fonts/hyundai/HyundaiSansHead-Medium.woff) format("woff");
}
@font-face {
  font-family: "Hyundai Sans Text";
  src: url(../fonts/hyundai/HyundaiSansText-Regular.woff2) format("woff2"), url(../fonts/hyundai/HyundaiSansText-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Hyundai Sans Text";
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/hyundai/HyundaiSansText-Italic.woff2) format("woff2"), url(../fonts/hyundai/HyundaiSansText-Italic.woff) format("woff");
}
@font-face {
  font-family: "Hyundai Sans Text";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/hyundai/HyundaiSansText-Bold.woff2) format("woff2"), url(../fonts/hyundai/HyundaiSansText-Bold.woff) format("woff");
}
@font-face {
  font-family: "Hyundai Sans Text";
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/hyundai/HyundaiSansText-BoldItalic.woff2) format("woff2"), url(../fonts/hyundai/HyundaiSansText-BoldItalic.woff) format("woff");
}